import {
    Category,
    CompanySearchResultItemDto,
    HealthCheckReportEntryDetailed,
    HealthStatusReportDetailed,
    PersonSearchResultItemDto,
    SearchResultSgmItemRelatedToDto,
    SgSearchResultItemDto,
    SgmSearchResultItemDto,
    SgmServiceLite,
    Subcategory,
} from './SgmSearchApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './SgmSearchApiSchema';

export class BaseSgmSearchApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.CompanySearchResultItemDto:
                return (value as CompanySearchResultItemDto).referenceId || '';
            case ObjectsWithoutIdPropertyEnum.PersonSearchResultItemDto:
                return (value as PersonSearchResultItemDto).referenceId || '';
            case ObjectsWithoutIdPropertyEnum.SearchResultSgmItemRelatedToDto:
                return (value as SearchResultSgmItemRelatedToDto).referenceId || '';
            case ObjectsWithoutIdPropertyEnum.SgSearchResultItemDto:
                return (value as SgSearchResultItemDto).referenceId || '';
            case ObjectsWithoutIdPropertyEnum.SgmSearchResultItemDto:
                return (value as SgmSearchResultItemDto).referenceId || '';
            case ObjectsWithoutIdPropertyEnum.SgmServiceLite:
                return (value as SgmServiceLite).serviceKey || '';
            case ObjectsWithoutIdPropertyEnum.Category:
                return (value as Category).key || '';
            case ObjectsWithoutIdPropertyEnum.Subcategory:
                return (value as Subcategory).key || '';
            case ObjectsWithoutIdPropertyEnum.HealthStatusReportDetailed:
                return (value as HealthStatusReportDetailed).apiCode || '';

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = type;
        }
        return '';
    }
}
